import { transformMessage } from '@wix/wix-chat-transform-bo-events/dist/lib/transform-message';

const context: any = {};
let requestId = 0;

export const chatApi = {
  //usage: $w('#wixChat1').onMessage(callbackToBeCalledWhenANewMessageArrives)
  onMessage: callback => {
    context.pubSub.subscribe('ChatWidget.onMessage', ({ data }) =>
      callback(transformMessage(data, 'BUSINESS_TO_CUSTOMER')),
    );
  },
  //usage: $w('#wixChat1').startPrivateChat(userId)
  startPrivateChat: userId => {
    requestId++;
    return new Promise((resolve, reject) => {
      context.pubSub.subscribe(
        `ChatWidget.startPrivateChatResponse.${requestId}`,
        ({ data: { success } }) => {
          context.pubSub.unsubscribe(
            `ChatWidget.startPrivateChatResponse.${requestId}`,
          );
          success ? resolve() : reject();
        },
      );
      context.pubSub.publish(
        `ChatWidget.startPrivateChat`,
        { userId, requestId },
        false,
      );
    });
  },
};

const pageReadyImpl = $w => {};

export const createControllers = controllerConfigs => {
  return controllerConfigs.map(() =>
    Promise.resolve({
      pageReady: pageReadyImpl,
      exports: chatApi,
    }),
  );
};

export const initAppForPage = ({ instance }, { pubSub }) => {
  context.pubSub = pubSub;
  return Promise.resolve();
};
